// extracted by mini-css-extract-plugin
export var btnDark = "terms-module--btn-dark--5cfef";
export var btnLight = "terms-module--btn-light--75eab";
export var buttonDefault = "terms-module--button-default--97c2d";
export var buttonLarge = "terms-module--button-large--605a4";
export var buttonSmall = "terms-module--button-small--ff382";
export var ctaLarge = "terms-module--cta-large--bb304";
export var ctaSmall = "terms-module--cta-small--8463c";
export var highlight = "terms-module--highlight--e8921";
export var input = "terms-module--input--dbac9";
export var labelLarge = "terms-module--label-large--3fb6c";
export var labelMedium = "terms-module--label-medium--cf0b8";
export var languages = "terms-module--languages--2752a";
export var link = "terms-module--link--8d897";
export var mainTitle = "terms-module--main-title--3aed9";
export var optionContainer = "terms-module--optionContainer--2000e";
export var optionListContainer = "terms-module--optionListContainer--f89e9";
export var pLarge = "terms-module--p-large--7906f";
export var pLargeBold = "terms-module--p-large-bold--7fa46";
export var pMedium = "terms-module--p-medium--df868";
export var pSmall = "terms-module--p-small--ebbf6";
export var searchWrapper = "terms-module--search-wrapper--eeadf";
export var singleChip = "terms-module--singleChip--d783b";
export var slickNext = "terms-module--slick-next--9ea99";
export var slickPrev = "terms-module--slick-prev--243a7";
export var slickSlide = "terms-module--slick-slide--71bfc";
export var splitContent = "terms-module--split-content--95803";
export var termsContainer = "terms-module--terms-container--b5993";
export var titleLarge = "terms-module--title-large--9bdd9";
export var titleMedium = "terms-module--title-medium--a8443";
export var titleSmall = "terms-module--title-small--0a119";
export var titleXl = "terms-module--title-xl--1d153";